<template>
  <div>

    <div class="card">
      <div class="card_warp">
        <div class="header">{{volist_parent.discuss_title}}</div>
        <div class="info">
          发帖人：{{volist_parent.user_name}}
          {{volist_parent.is_edu_user == 0 ?'学生':'教师'}} <span style="margin:0 5px">|</span>
          发帖时间：{{volist_parent.discuss_time}}
        </div>
        <div class="fixed_right_btn">
          <el-button type="text"
                     v-if="volist_parent.is_top != 0">已置顶</el-button>
          <el-button type="danger"
                     v-if="volist_parent.is_over != 0">已结帖</el-button>
        </div>
        <div class="topic_warp">
          {{volist_parent.discuss_content}}
        </div>
        <div v-if="volist_parent.question_id "
             class="show_btn">
          <el-button type="text"
                     v-if="!isShow"
                     @click="show"
                     size="mini">显示题目<i class="el-icon-arrow-down"></i></el-button>
          <el-button type="text"
                     @click="show"
                     v-else
                     size="mini">收起<i class="el-icon-arrow-up"></i></el-button>
        </div>
        <div v-if=" isShow"
             class="topic">
          <span class="tou">题目分数:</span>{{volist_parent.question_score}}
          <div class="line">
            <span class="tou">
              题目内容:
            </span>

            <div v-if="volist_parent.question_head_content !== ''"
                 v-html="volist_parent.question_head_content">
            </div>
            <div v-html="volist_parent.question_content"></div>
            <div class="line_border_bottom"></div>
          </div>
          <div class="line">
            <span class="tou">
              题目答案：
            </span>
            <div v-html="volist_parent.question_answer">
            </div>
            <div class="line_border_bottom"></div>

          </div>
          <div class="line">
            <span class="tou">
              题目解释：
            </span>
            <div v-html="volist_parent.question_explain">
            </div>
          </div>
        </div>
        <div class="images"
             v-if="volist_parent.discuss_image_count != 0">
          <div>
            <el-row :gutter="10">
              <el-image style="width: 100px; height: 100px;border-radius: 5px; margin:10px"
                        v-for="item in volist_parent.discuss_image"
                        :key="item"
                        :src="item"
                        :preview-src-list="volist_parent.discuss_image">
              </el-image>
            </el-row>
          </div>
        </div>
        <div class="video"
             v-if="volist_parent.discuss_video">
          <div>
            <video v-if="volist_parent.discuss_video"
                   :src="$addHeader(volist_parent.discuss_video)"
                   controls="controls">
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
    </div>

    <div class="daan_warp"
         v-for="(item,index) in list"
         :key="index">
      <div class="user_info">
        <div class="left_info">
          回复人：{{item.user_name}}
          {{item.is_edu_user == 0?'学生':'教师'}}&nbsp;|&nbsp;
          <span>
            回复时间：{{item.discuss_time}}
          </span>
        </div>
        <!-- <div class="right_button">
          <img src="@/assets/answer/dianzan.png"
               @click="give(item)"
               alt="">
          <span style="color:#409EFF">
            {{'(' + item.discuss_upvote_times + ')'}}
          </span>
          <el-button size="small"
                     type="text"
                     style="color:red"
                     @click="delTie(item)"> <i class="el-icon-delete"></i></el-button>
        </div> -->
      </div>
      <div class="daan_content">
        {{item.discuss_content}}
      </div>
      <div class="warp"
           style="color:black">
        <div class="images"
             v-if="item.discuss_image_count != 0">
          <div>
            <el-image style="width: 100px; height: 100px;border-radius: 5px; margin:10px"
                      v-for="item1 in item.discuss_image"
                      :key="item1"
                      :src="item1"
                      :preview-src-list="item.discuss_image">
            </el-image>
          </div>
        </div>
        <!-- 视频区域 -->
        <div class="video"
             v-if="item.discuss_video">
          视频
          <div>
            <video :src="$addHeader(item.discuss_video)"
                   controls="controls">
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
      volist_parent: {},
      list: [],
      question_all: {},
      isShow: false,
      timer: ''
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    give (item) {
      if (this.timer) {
        return
      } else {
        this.timer = setTimeout(() => {
          this.timer = ''
        }, 2000);
      }
      this.$http({
        url: '/api/v2/stats/good',
        method: 'post',
        data: {
          discuss_id: item.discuss_id,
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '点赞成功!',
          type: 'success'
        });
        this.getInfo()
      })
    },

    getInfo () {
      this.$http({
        url: '/api/v2/stats/discuss_show',
        method: 'get',
        params: {
          discuss_id: this.$route.query.id
        }
      }).then(res => {
        this.volist_parent = res.data.discuss_parent
        this.$nextTick(() => {
          this.list = res.data.volist_children
        })
        // this.question_all = res.data.volist_children
      })
    },
    show () {
      this.isShow = !this.isShow
    },
    delTie (item) {
      console.log('item', item);
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/stats/discuss_del',
          method: 'post',
          data: {
            id: item.discuss_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功',
            type: 'success'
          });
          this.getInfo()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  .card_warp {
    background: #ffffff;
    position: relative;
    overflow: hidden;
    .fixed_right_btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .header {
      font-size: 18px;
      line-height: 40px;
      padding: 10px;
      padding-top: 0;
      font-weight: bold;
    }
    .info {
      font-size: 15px;
      text-indent: 1em;
      color: #aaacae;
      border-bottom: 1px solid #e8eaec;
      padding-bottom: 10px;
    }
    .topic_warp {
      text-indent: 1em;
      padding: 10px;
      font-size: 16px;
    }
    .show_btn {
      display: flex;
      justify-content: center;
      i {
        font-size: 16px;
      }
    }
    .topic {
      .line {
        margin: 10px 0;
        padding: 10px;
        position: relative;
        // border-bottom: 1px solid #f0f2f5;
        .line_border_bottom {
          width: 98%;
          height: 10px;
          border-bottom: 1px solid #c1c1c1;
          margin: 0 auto;
        }
        div {
          text-indent: 1em;
        }
      }
      .tou {
        display: inline-block;
        text-indent: 1em;
        font-size: 16px;
        font-weight: bold;
        margin: 10px;
        border-left: 4px solid #409eff;
      }
    }
  }
}

.warp {
  font-size: 14px;
  margin-right: 30px;
  color: #606194;
  word-break: break-all;
}

::v-deep .el-button--medium {
  font-size: 18px;
}
.img {
  width: 200px;
  height: 170px;
  margin: 15px;
  cursor: pointer;
}
.daan_warp {
  margin-top: 20px;
  background: white;
  margin-bottom: 20px;
  // margin-right: 70px;
  border-radius: 5px;
  // margin-left: 10px;
  box-shadow: 0px 0px 4px 2px rgba(234, 234, 234, 0.5);
  .user_info {
    display: flex;
    margin: 10px;
    border-bottom: 1px solid #f1f3f4;
    .left_info {
      flex: 1;
      color: #aaacae;
      padding: 10px;
      font-size: 15px;
    }
    .right_button {
      width: 200px;
      text-align: right;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 18px;
        cursor: pointer;
      }
      span {
        margin-right: 20px;
        font-size: 14px;
      }
    }
  }
  .daan_content {
    padding: 15px;
    font-size: 16px;
    padding: 10px;
    margin-right: 40px;
  }
}
::v-deep [class*=" el-icon-"],
[class^="el-icon-"] {
  font-size: 18px;
}

.images {
  padding: 5px;
}
.video {
  padding: 5px;
}

.item {
  display: flex;
  position: absolute;
  right: 100px;
  justify-content: center;
  color: #ff6147;
  align-items: center;
  img {
    right: 0;
    width: 20px;
    height: 20px;
    margin-right: -13px;
  }
  span {
    &:nth-child(1) {
      width: 51%;
      display: flex;
      align-items: center;
      margin-left: 25px;
    }
    &:nth-child(2) {
      text-align: center;
    }
  }
}
</style>